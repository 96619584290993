<template>
    <div class="row">
        <div v-for="pack in picklPacks" :key="pack.id" class="col-xs-12 col-sm-6 col-md-4">
            <div class="pickl-pack center-block" :class="{ oldPrice: pack.actual_price > 0 && pack.actual_price != pack.price, sale: pack.discount > 0 }">
                <h3 v-autosize="30">{{pack.name}}</h3>
                <s v-if="pack.actual_price > 0 && pack.actual_price != pack.price">${{pack.actual_price}}/month</s>
                <strong v-autosize="35">${{pack.price}}</strong>/Month<br />
                <span>{{pack.pickl_credits}} Pickls</span><br />
                <button type="button" @click="checkout(pack)">BUY NOW</button>
                <div class="discount" v-if="pack.discount > 0">{{pack.discount}}%</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      picklPacks: [],
      selectedPack: {}
    }
  },
  mounted () {
    this.getSubscriptionPacks()
  },
  methods: {
    getSubscriptionPacks () {
      this.$http
        .get('subscriptions/listed')
        .then(
          function (response) {
            this.picklPacks = response.body.data
          }
        )
    },
    checkout (pack) {
      this.selectedPack = pack
      // this.$checkout.close()
      // is also available.
      this.$checkout.open({
        name: 'Pickl',
        currency: 'USD',
        email: this.$store.state.userData.email,
        amount: pack.price * 100,
        token: (token) => {
          this.$store.commit('showLoading')
          this.SubscribePack(token)
          // Send the token to your server
          // for payment or subscription handling,
          // or do whatever you want with it
          // I don't really care.
        }
      })
    },
    SubscribePack (token) {
      this.$http
        .post('subscribe-pack', { 'token_details': token, 'pack_details': this.selectedPack })
        .then(
          function (response) {
            if (response.body.status === true) {
              this.$router.push('/brand/dashboard')
              this.$store.commit('showMessage', { message: 'Transaction<br /> Successful!' })
            } else {
              this.$notify({ type: 'error', text: response.body, duration: -1 })
              this.$store.commit('hideMessage')
            }
          },
          function (response) {
            this.$notify({ type: 'error', text: 'An error occurred while processing your transaction...', duration: -1 })
            this.$store.commit('hideMessage')
          }
        )
    }
  }

}
</script>

<style scoped>
.pickl-pack {
    background-image: url("/img/Video explaining/packbg.png");
    background-size: 100% 100%;
    text-align: center;
    font-size: 22px;
    padding: 60px 35px 25px;
    color: rgba(0,0,0,0.8);
    max-width: 250px;
    position: relative;
}

h3 {
    font-weight: 500;
    color: #782b54;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 40px;
    line-height: 30px;
    height: 30px;
}

s {
  text-decoration: line-through;
  display: inline-block;
  padding: 0 10px;
  color: rgba(0,0,0,0.5);
  margin: 10px 0 5px;
}

.oldPrice h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

strong {
    font-weight: 500;
    font-size: 35px;
    line-height: 35px;
    display: inline-block;
    max-width: 50%;
    height: 35px;
}

span {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 20px;
    display: inline-block;
}

button {
    appearance: none;
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1px;
    background: #12db6d;
    padding: 0;
    outline: none;
    border: none;
    color: white;
    box-shadow: none;
}

.discount {
  font-size: 35px;
  line-height: 50px;
  height: 55px;
  width: 90px;
  margin: 20px auto 0;
  background-image: url("/img/Pack/sale.png");
  text-align: center;
  background-size: 100% 100%;
  overflow: visible;
  background-repeat: no-repeat;
  color: white;
  font-weight: 700;
  position: absolute;
  top: 40px;
  left: -25px;
  transform: rotateZ(-45deg);
}
</style>
